import React, { useState } from "react";
import Modal from "../Modal";
import objOne from "../images/Section-4/obj-1.png";
import objTwo from "../images/Section-4/obj-2.png";
import objThree from "../images/Section-4/obj-3.png";

export default function SectionFour() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full relative overflow-hidden pt-8 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* Main Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Content */}
          <div className="p-4 space-y-8">
            <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl hover:text-[#4F9443] font-semibold text-left">
              Premium Digital Marketing Engagement
            </p>
            <div className="grid grid-cols-1 gap-6">
              {/* Cards */}
              {[
                {
                  img: objOne,
                  title: "Packed appointment schedules",
                  points: [
                    "Full appointment books",
                    "Qualified patient leads",
                    "Consistent growth pipeline",
                  ],
                },
                {
                  img: objTwo,
                  title: "Efficiency Booster",
                  points: [
                    "40% less administrative growth",
                    "Streamlined patient communication",
                    "Automated follow-ups",
                  ],
                },
                {
                  img: objThree,
                  title: "Growth Partner",
                  points: [
                    "Dedicated success manager",
                    "Performance tracking",
                    "ROI-focused strategies",
                  ],
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="bg-[#EAFFE6] shadow-lg rounded-lg p-4 space-y-4 hover:bg-[#D4F5CC] transform transition-transform duration-300 hover:scale-105"
                >
                  {/* Card Header */}
                  <div className="bg-[#035474] p-4 sm:p-6 flex items-center gap-4 rounded-md text-white">
                    <img src={item.img} alt={item.title} className="w-12 h-12" />
                    <p className="text-lg sm:text-xl lg:text-2xl font-bold">
                      {item.title}
                    </p>
                  </div>
                  {/* Points */}
                  <div className="space-y-2">
                    {item.points.map((point, i) => (
                      <div
                        key={i}
                        className="flex items-center text-[#035474] space-x-2 hover:text-[#4F9443] transition-colors duration-300"
                      >
                        <span className="bg-[#035474] w-2 h-2 rounded-full"></span>
                        <p className="text-sm sm:text-base lg:text-lg">{point}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Content */}
          <div className="flex justify-center md:justify-end">
            <iframe
              className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl h-60 sm:h-80 md:h-96 lg:h-[500px] rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/8F7A3sg8l5o"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Call-to-Action Button */}
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start items-center space-y-4 md:space-y-0">
          <span className="bg-[#4F9443] h-[5px] md:h-[10px] w-full md:w-5/12"></span>
          <button
            onClick={openModal}
            className="bg-[#4F9443] text-white rounded-full py-2 px-6 text-lg sm:text-xl md:text-2xl lg:text-4xl font-semibold transform transition-transform duration-300 hover:scale-105 active:scale-95"
          >
            Get Your Digital Audit
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="p-8">
          <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-4 text-center">
            Your Free Digital Audit
          </h2>
          <p className="text-sm sm:text-base lg:text-lg mb-4 text-center">
            Get insights into how to optimize your digital presence and grow your business.
          </p>
          <div className="flex justify-center">
            <button
              onClick={closeModal}
              className="bg-[#4F9443] text-white rounded-full m-0 py-2 px-6 text-lg sm:text-xl transform transition-transform duration-300 hover:scale-105 active:scale-95"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
