import { render } from "react-dom";
import React, { useState } from "react";
import Modal from "../Modal";
import imgOne from "../images/Section-2/section-two-img-one.png";
import imgTwo from "../images/Section-2/section-two-img-two.png";
import objOne from "../images/Section-2/obj-1.png";
import objTwo from "../images/Section-2/obj-2.png";
import objThree from "../images/Section-2/obj-3.png";
import objFour from "../images/Section-2/obj-4.png";
import dumoshimg from "../images/Section-1/dlogowhite.png";

export default function SectionTwo() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="w-full relative overflow-hidden pt-8 bg-gray-50">
      {/* Main Section */}
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* First Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="flex flex-col space-y-4">
            <div className="m-2">
              <div className="flex flex-row justify-start items-center gap-4 my-4">
                <p className="text-xl md:text-3xl lg:text-4xl lg:text-[2.10rem] text-left hover:text-[#4F9443] transition duration-300 ">
                  Why Top Doctors Trust
                </p>
                <img
                  src={dumoshimg}
                  alt="Logo"
                  className="w-[120px] md:w-[160px] lg:w-[200px] hover:scale-110 transition-transform duration-300"
                />
              </div>
              <p className="text-xl md:text-3xl lg:text-4xl text-left hover:text-[#4F9443] transition duration-300">
                Your medical expertise took years to
              </p>
              <p className="text-xl md:text-3xl lg:text-4xl text-left hover:text-[#4F9443] transition duration-300">
                master. Your marketing shouldn't.
              </p>
            </div>
          </div>
          <div className="flex justify-center md:justify-end">
            <img
              src={imgOne}
              alt="Medical Illustration"
              className="w-64 hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>

        {/* Second Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-4">
            <div className="flex flex-row items-center gap-4">
              <span className="bg-black w-2 h-2"></span>
              <p className="text-2xl md:text-3xl lg:text-4xl text-left my-4 hover:text-[#4F9443] transition duration-300">
                Superb Patient Tele-Calling Support
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Cards with Hover */}
              {[objOne, objTwo, objThree, objFour].map((obj, index) => (
                <div
                  key={index}
                  className="bg-[#EAFFE6] shadow-lg text-lg text-[#035474] rounded-lg text-left p-4 transition-transform duration-300 hover:bg-[#D4F5CC] hover:shadow-2xl hover:scale-105"
                >
                  <div
                    className={`bg-[#035474] rounded-lg ${
                      obj === objThree ? "p-6" : "px-4 py-2"
                    } w-20 h-20 sm:w-24 sm:h-24 lg:w-32 lg:h-32 flex justify-center items-center mt-[-30px] hover:scale-110 transition-transform duration-300`}
                  >
                    <img
                      src={obj}
                      alt={`Feature ${index + 1}`}
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                  {index === 0 && (
                    <>
                      <p className="sm:text-base lg:text-lg">
                        Convert inquiries to appointments within minutes
                      </p>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <p className="sm:text-base lg:text-lg">
                        Deliver 70% higher conversion rates
                      </p>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <p className="sm:text-base lg:text-lg">
                        Reduce no-show by 65% through smart scheduling
                      </p>
                    </>
                  )}
                  {index === 3 && (
                    <>
                      <p className="sm:text-base lg:text-lg">
                        Ensure treatment completion through systematic
                        follow-ups
                      </p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center md:justify-end">
            <img
              src={imgTwo}
              alt="Audience listening to a speaker in a seminar"
              className="w-full sm:w-3/4 lg:w-2/3 xl:w-1/2 hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>
      </div>
      
      {/* Bottom Section */}
      <div className="max-w-7xl my-6 mx-auto px-4 flex flex-col md:flex-row justify-center md:justify-start items-center space-y-4 md:space-y-0">
        <span className="bg-[#4F9443] h-[5px] md:h-[10px] w-full md:w-5/12"></span>
        <button
          onClick={openModal}
          className="bg-[#4F9443] text-white rounded-full m-0 py-2 px-6 text-lg sm:text-xl md:text-2xl lg:text-4xl transform transition-transform duration-300 hover:scale-105 active:scale-95"
        >
          Know how
        </button>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

render(<SectionTwo />, document.getElementById("root"));
