import axios from "axios";
import React, { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

// Define the validation schema
const ModalSchema = Yup.object().shape({
  fullName: Yup.string().required("Please enter your full name"),
  contactNumber: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .max(10, "Number should be of 10 digits")
    .min(10, "Number should be of 10 digits")
    .required("Please enter contact number"),
  email: Yup.string().email("Invalid email"),
  location: Yup.string().required("Please select your clinic location"),
  revenue: Yup.string().required("Please select your current monthly revenue"),
  target: Yup.string().required("Please select your target monthly goal"),
  marketingBudget: Yup.string().required("Please select your marketing budget"),
  appointments: Yup.string().required(
    "Please specify the number of appointments"
  ),
  connectTime: Yup.date().required("Please select a connection time"),
});

function Modal({ isOpen, onClose }: any) {
  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState();
  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const currentDateTime = new Date().toISOString().slice(0, 16);

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(countryList);
    const defaultCountry = countryList.find(
      (country) => country.isoCode === "IN"
    );
    if (defaultCountry) {
      const stateList = State.getStatesOfCountry(defaultCountry.isoCode);
      setStates(stateList);
    }
  }, []);

  const handleStateChange = (selectedOption: any) => {
    setSelectedState(selectedOption.target.value);
    const stateCode = selectedOption.target.value;
    setLoadingCities(true);
    const cityList = City.getCitiesOfState("IN", stateCode);
    setCities(cityList);
    setLoadingCities(false);
  };

  const handleSubmit = async (values: any) => {
    const payload = values;

    payload.submissionTime = new Date().toLocaleString();
    setLoadingSubmit(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_N8N_URL || "",
        values
      );

      await response;

      console.log("Response from n8n:", response.data);
      if (response.data != null)
        toast.success(
          "Thank you for your interest! We will get in touch soon."
        );

      onClose();
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Failed to save data. Please try again.");
    } finally {
      setLoadingSubmit(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 p-4">
      <div className="bg-white p-6 rounded-md shadow-lg relative w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl max-h-[85vh] overflow-y-auto sm:p-8">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4 text-center text-[#4F9443]">
          Enter Your Details
        </h2>
        <Formik
          initialValues={{
            fullName: "",
            contactNumber: "",
            email: "",
            owner: "",
            speciality: "",
            websiteLink: "",
            location: "",
            revenue: "",
            target: "",
            marketingBudget: "",
            appointments: "",
            connectTime: "",
          }}
          validationSchema={ModalSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, handleChange }) => (
            <Form className="space-y-6">
              {/* Full Name (Required) */}
              <label className="text-left block">
                Full Name <span className="text-red-500">*</span>
                <Field
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Mobile Number (Required) */}
              <label className="text-left block">
                Contact Number <span className="text-red-500">*</span>
                <Field
                  type="text"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="contactNumber"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Email (Optional) */}
              <label className="text-left block">
                Email Address
                <Field
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Owner (Optional) */}
              <label className="text-left block">
                Are you the owner?
                <Field
                  type="text"
                  name="owner"
                  placeholder="Yes/No"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="owner"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Speciality (Optional) */}
              <label className="text-left block">
                What is your speciality?
                <Field
                  type="text"
                  name="speciality"
                  placeholder="Speciality"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="speciality"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Website Link (Optional) */}
              <label className="text-left block">
                Website Link
                <Field
                  type="text"
                  name="websiteLink"
                  placeholder="Website Link"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                />
                <ErrorMessage
                  name="websiteLink"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Location (Required) */}
              <label className="text-left block">
                Where is your clinic located?{" "}
                <span className="text-red-500">*</span>
                <select
                  onChange={(option) => handleStateChange(option)}
                  name="state"
                  value={selectedState}
                  className="mb-2 border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                >
                  <option>State</option>
                  {states &&
                    states.map((state: any, index: any) => {
                      return (
                        <option key={index} value={state.isoCode}>
                          {state.name}
                        </option>
                      );
                    })}
                </select>
                <select
                  name="location"
                  className="border p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#4F9443]"
                  onChange={handleChange}
                  value={values.location}
                >
                  <option>City</option>
                  {cities &&
                    cities.map((city: any, index: any) => {
                      return (
                        <option key={index} value={city.name}>
                          {city.name}
                        </option>
                      );
                    })}
                </select>
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Revenue (Required) */}
              <label className="text-left block">
                What is your current monthly revenue?{" "}
                <span className="text-red-500">*</span>
                <div className="flex flex-col">
                  {[
                    "Rs.0-1.5 Lacs",
                    "Rs.2 Lacs  - 5 Lacs ",
                    "Rs.5 Lacs  - 10 Lacs ",
                    "Rs.10 Lacs - 30 Lacs",
                    "Rs.30 Lacs - 1 Cr",
                    "Rs.1Cr Above",
                  ].map((range) => (
                    <label key={range} className="flex items-center">
                      <Field
                        type="radio"
                        name="revenue"
                        value={range}
                        className="mr-2"
                      />
                      {range}
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  name="revenue"
                  component="div"
                  className="text-red-600"
                />
              </label>

              {/* Target (Required) */}
              <label className="text-left block">
                What is your target monthly goal working with us?{" "}
                <span className="text-red-500">*</span>
                <div className="flex flex-col">
                  {[
                    "Rs.2 Lacs  - 5 Lacs ",
                    "Rs.5 Lacs  - 10 Lacs ",
                    "Rs.10 Lacs - 30 Lacs",
                    "Rs.30 Lacs - 1 Cr",
                    "Rs.1Cr Above",
                  ].map((range) => (
                    <label key={range} className="flex items-center">
                      <Field
                        type="radio"
                        name="target"
                        value={range}
                        className="mr-2"
                      />
                      {range}
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  name="target"
                  component="div"
                  className="text-red-600"
                />
              </label>

              {/* Marketing Budget (Required) */}
              <label className="text-left block">
                How much are you looking to invest in advertisements for
                acquiring new customers? <span className="text-red-500">*</span>
                <div className="flex flex-col">
                  {[
                    "Rs.0-1.5 Lacs",
                    "Rs.2 Lacs  - 5 Lacs ",
                    "Rs.5 Lacs  - 10 Lacs ",
                    "Rs.10 Lacs - 30 Lacs",
                    "Rs.30 Lacs - 1 Cr",
                    "Rs.1Cr Above",
                  ].map((range) => (
                    <label key={range} className="flex items-center">
                      <Field
                        type="radio"
                        name="marketingBudget"
                        value={range}
                        className="mr-2"
                      />
                      {range}
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  name="marketingBudget"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              {/* Number of Appointments (Required) */}
              <label className="text-left block">
                How many appointments can your clinic handle in a month?{" "}
                <span className="text-red-500">*</span>
                {["30 - 40", "40 - 50", "60 - 70", "70 above"].map((range) => (
                  <label key={range} className="flex items-center">
                    <Field
                      type="radio"
                      name="appointments"
                      value={range}
                      className="mr-2"
                    />
                    {range}
                  </label>
                ))}
                <ErrorMessage
                  name="appointments"
                  component="div"
                  className="text-red-600 text-sm"
                />
              </label>

              <label className="text-left block">
                When would you like to connect?{" "}
                <span className="text-red-500">*</span>
                <Field
                  type="datetime-local"
                  min={currentDateTime}
                  name="connectTime"
                  className="border p-2 w-full rounded-md"
                />
                <ErrorMessage
                  name="connectTime"
                  component="div"
                  className="text-red-600"
                />
              </label>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={loadingSubmit}
                className={`w-full bg-[#4F9443] text-white py-3 px-4 rounded-md mt-4 ${
                  loadingSubmit ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loadingSubmit ? "Submitting..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Modal;
