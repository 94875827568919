import { render } from "react-dom";
import imgOne from "../images/Section-3/section-three-img.png";
import objOne from "../images/Section-3/obj-1.png";
import objTwo from "../images/Section-3/obj-2.png";
import objThree from "../images/Section-3/obj-3.png";
import objFour from "../images/Section-3/obj-4.png";
import Modal from "../Modal";
import { useState } from "react";

export default function SectionThree() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full relative overflow-hidden pt-8 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* Main Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Content */}
          <div>
            <div className="flex flex-row justify-start items-center gap-4">
              <span className="bg-black w-2 h-2"></span>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl font-semibold text-left hover:text-[#4F9443] transition-colors duration-300">
                Premium Digital Marketing Engagement
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
              {/* Cards with Hover */}
              {[objOne, objTwo, objThree, objFour].map((image, index) => (
                <div
                  key={index}
                  className="bg-[#EAFFE6] shadow-lg text-sm sm:text-base md:text-lg text-[#035474] rounded-lg p-4 flex flex-col items-center transition-transform duration-300 hover:bg-[#D4F5CC] hover:shadow-2xl hover:scale-105"
                >
                  <div
                    className="bg-[#035474] 
            w-16 h-16 
            sm:w-24 sm:h-24 
            md:w-28 md:h-28 
            lg:w-32 lg:h-32 
            xl:w-36 xl:h-36 
            rounded-lg flex justify-center items-center mt-[-20px] 
            hover:scale-110 transition-transform duration-300"
                  >
                    <img
                      src={image}
                      alt={`Object ${index + 1}`}
                      className="w-3/4 h-3/4 object-contain"
                    />
                  </div>

                  <p className="text-center font-semibold mt-2 px-2 sm:px-4">
                    {
                      [
                        "Dominate Google Ads & META Platforms",
                        "Capture high-intent patient searches",
                        "Build your authority across social media",
                        "Outshine competition through strategic content",
                      ][index]
                    }
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image */}
          <div className="flex justify-center md:justify-end">
            <img
              src={imgOne}
              alt="Digital Marketing Representation"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>

        {/* Call-to-Action Button */}
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start items-center space-y-4 md:space-y-0">
          <span className="bg-[#4F9443] h-[5px] md:h-[10px] w-full md:w-5/12"></span>
          <button
            onClick={openModal}
            className="bg-[#4F9443] text-white rounded-full m-0 py-2 px-6 text-lg sm:text-xl md:text-2xl lg:text-4xl font-semibold transform transition-transform duration-300 hover:scale-105 active:scale-95"
          >
            Discover how
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

render(<SectionThree />, document.getElementById("root"));
