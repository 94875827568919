import { render } from "react-dom";
import dumoshimg from "../images/Section-1/dlogowhite.png";
import sectionOneImg from "../images/Section-1/section-one-img.png";
import Modal from "../Modal";
import { useState } from "react";

export default function SectionOne() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full relative overflow-hidden pt-8 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* Top Section */}
        <div className="flex justify-end">
          <div className="flex justify-center md:justify-end">
            <img
              src={dumoshimg}
              alt="Logo"
              className="w-24 sm:w-32 md:w-48 lg:w-64 hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>

        {/* Main Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Section with Hover Effect */}
          <div className="flex justify-center items-center relative group">
            <img
              src={sectionOneImg}
              alt="Medical illustration"
              className="w-48 sm:w-64 md:w-80 lg:w-full transform transition-transform duration-300 group-hover:scale-105"
            />
          </div>

          {/* Right Section */}
          <div className="flex flex-col space-y-4 px-2 md:px-6">
            <div>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl text-left font-bold text-[#4F9443] hover:text-[#317a2f] transition-colors duration-300">
                “Treat Patients.
              </p>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl text-left font-bold text-[#4F9443] hover:text-[#317a2f] transition-colors duration-300">
                Not Marketing Headaches.”
              </p>
            </div>
            <div>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl text-left hover:underline transition-all duration-300">
                The Dumosh Effect
              </p>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-4xl text-left hover:underline transition-all duration-300">
                Double Your Patient Walk-ins,
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="max-w-7xl my-6 mx-auto px-4 flex flex-col md:flex-row justify-center md:justify-start items-center space-y-4 md:space-y-0">
        <span className="bg-[#4F9443] h-[5px] md:h-[10px] w-full md:w-5/12"></span>
        <button
          onClick={openModal}
          className="bg-[#4F9443] text-white rounded-full m-0 py-2 px-6 text-lg sm:text-xl md:text-2xl lg:text-4xl transform transition-transform duration-300 hover:scale-105 active:scale-95"
        >
          Know how
        </button>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

render(<SectionOne />, document.getElementById("root"));
