import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Modal from "./Modal";
import SectionFour from "./ components/SectionFour";
import LastSection from "./ components/LastSection";
import SectionThree from "./ components/SectionThree";
import SectionTwo from "./ components/SectionTwo";
import SectionFive from "./ components/SectionFive";
import "@fontsource/roboto"; // Defaults to weight 400
import SectionOne from "./ components/SectionOne";
import { Toaster } from "react-hot-toast";

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="App relative">
      {/* Toaster for displaying toast notifications */}
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
      {/* Main Content */}
      <div className="container mx-auto">
        <section className="text-center">
          <SectionOne />
          <SectionTwo />
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <LastSection />
        </section>
        <Modal isOpen={isModalOpen} onClose={closeModal} />
      </div>

      {/* Side Sticker */}
      <div
        className="fixed right-0 top-1/3 z-50 bg-[#4F9443] text-white font-bold text-sm sm:text-base rounded-l-full cursor-pointer shadow-lg transition-all duration-300 hover:scale-105 hover:w-40 hover:py-2 flex items-center justify-center min-h-[100px]"
        onClick={openModal}
      >
        <span className="rotate-90 whitespace-nowrap">Contact Us</span>
      </div>
    </div>
  );
}

export default App;
