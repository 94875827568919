import React, { useState } from "react";
import imgOne from "../images/Section-5/obj-1.png";
import dumoshimg from "../images/Section-1/dlogowhite.png";
import Modal from "../Modal";

export default function SectionFive() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full relative overflow-hidden pt-8 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* Main Section */}
        <div className="space-y-4">
          {/* Header Text */}
          <div className="flex flex-col gap-4">
            <p className="text-[#035474] text-2xl sm:text-3xl lg:text-4xl font-extrabold text-left">
              Don’t Let Empty Slots Cost You
            </p>
            <p className="text-[#035474] text-lg sm:text-xl lg:text-2xl text-left">
              While you’re reading this, your competitors are growing their
              practices. Take control of your practice growth today.
            </p>
          </div>

          {/* Branding Section */}
          <div className="flex items-center gap-2 hover:scale-105 transition-transform duration-300">
            <p className="text-[#035474] text-lg sm:text-xl lg:text-2xl font-medium">
              The
            </p>
            <img
              src={dumoshimg}
              alt="Dumosh logo"
              className="h-12 sm:h-16 lg:h-20 hover:rotate-12 transition-transform duration-300"
            />
            <p className="text-[#035474] text-lg sm:text-xl lg:text-2xl font-medium">
              difference
            </p>
          </div>
        </div>

        {/* Feature Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Features List */}
          <div className="space-y-6">
            {[
              "Performance - Based Pricing",
              "Industry - Leading Conversion Rates",
              "Dedicated Support Team",
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-[#C2DCE7] border-[#4F9443] border-[2px] rounded-xl p-4 transform transition-transform duration-300 hover:scale-105 hover:bg-[#D4F5CC]"
              >
                <p className="text-[#035474] text-lg sm:text-xl lg:text-2xl font-medium">
                  {feature}
                </p>
              </div>
            ))}
          </div>

          {/* Image Section */}
          <div className="flex justify-center md:justify-end">
            <img
              src={imgOne}
              alt="Marketing illustration"
              className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
            />
          </div>
        </div>

        {/* Call-to-Action Button */}
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start items-center space-y-4 md:space-y-0">
          <span className="bg-[#4F9443] h-[5px] md:h-[10px] w-full md:w-5/12"></span>
          <button
            onClick={openModal}
            className="bg-[#4F9443] text-white rounded-full m-0 py-3 px-6 text-lg sm:text-xl lg:text-2xl font-bold transform transition-transform duration-300 hover:scale-105 active:scale-95 hover:bg-[#3B7D37]"
          >
            Transform Your Practice Now{" "}
            <span className="text-[#FF0909] font-extrabold">!</span>
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}
