import React, { useState } from "react";
import Modal from "../Modal";

export default function LastSection() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full bg-[#035474] relative overflow-hidden mt-4 py-8">
      {/* Main Container */}
      <div className="max-w-7xl mx-auto px-4 flex flex-col space-y-8">
        {/* Header Section */}
        <div className="flex flex-col gap-4">
          <p className="text-white text-2xl sm:text-3xl lg:text-4xl font-bold text-left">
            Ready to Fill Your Practice?
          </p>
          <p className="text-white text-lg sm:text-xl lg:text-2xl text-left">
            Schedule your free 20-minute discovery call and see how DUMOSH can
            transform your patient acquisition.
          </p>
        </div>

        {/* Quote Section */}
        <div className="flex flex-col items-center text-center space-y-2">
          <p className="text-white font-light text-xl sm:text-2xl lg:text-3xl">
            "Your expertise deserves a full waiting room.             Let us deliver it."

          </p>
        </div>

        {/* Call-to-Action Button */}
        <div className="flex justify-center">
          <button
            onClick={openModal}
            className="bg-[#4F9443] text-white rounded-full m-0 py-3 px-8 text-lg sm:text-xl lg:text-2xl font-semibold transform transition-transform duration-300 hover:scale-105 active:scale-95"
          >
            Book Your Free Strategy Session
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}
